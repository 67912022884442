.sidebar {
  background-image: linear-gradient(to top right, #172AAF, #03589C);
  height: 100%;
  width: 15rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
}

.sidebar .logo {
  padding: 0 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: 5rem;
  width: 100%;
  text-align: center;
}

.sidebar .logo>span {
  background: url('../../../assets/img/fldrilling_logo_white.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: inline-block;
}
/* 
.sidebar .logo>.icon {
  margin: 0;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  margin-bottom: .5rem;
  font-size: 2rem;
}

.sidebar .logo>.ui.header {
  color: #FFFFFF;
  margin: 0;
  text-align: center;
} */

.sidebar .services>.service {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.sidebar .services>.service .svg-icon {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  margin-right: .5rem;
}

.sidebar .services>.service .svg-icon.taskTypes {
  width: 26px;
  height: 26px;
  background-size: 20px 26px;
  margin-left: 3px;
  margin-right: calc(.5rem - 3px);
}

.sidebar .services>.service .link {
  color: #FFFFFF;
}

.sidebar>.copyright {
  text-align: center;
  width: 100%;
  padding-bottom: 1rem;
  color: #FFFFFF;
  font-size: .8rem;
  font-weight: 400;
  margin-top: auto;
}

/* RESPONSIVE */
@media only screen and (max-width: 600px) {
  .sidebar {
    height: fit-content;
    width: 100%;
    position: relative;
    display: block;
  }

  .sidebar .services,
  .sidebar>.copyright {
    display: none;
  }

  .sidebar .logo {
    margin: 0 auto;
    height: 3rem;
    width: 100%;
  }
  
  .sidebar .logo>span {
    height: 100%;
    width: 5rem;
  }
}