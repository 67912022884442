.App {
  background-image: linear-gradient(to top right, #172AAF, #03589C);
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui.button.primary {
  background-color: #172AAF;
}

.ui.button.primary:hover,
.ui.button.primary:active,
.ui.button.primary:focus {
  background-color: rgb(41, 60, 204);
}

.ui.modal,
.ui.dropdown>.menu,
.ui.input>input,
.ui.dropdown,
.ui.button,
.ui.button.primary,
.ui.table {
  border-radius: 2px !important;
}

.ui.modal .ui.form button[type="submit"] {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.row.spc-btwn {
  justify-content: space-between;
}