.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
}

.login .ui.header {
  text-align: center;
  color: #FFF;
}

.login>.box {
  background-color: #FFF;
  border-radius: 2px;
  padding: 2rem;
  min-width: 30rem;
  min-height: 15rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login>.box .ui.header {
  color: #000;
}

.login>.box .ui.button {
  margin: 1rem auto 0;
}

.login .logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 5rem;
  width: 100%;
  text-align: center;
}

.login .logo>span {
  background: url('../../assets/img/fldrilling_logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 9rem;
  display: inline-block;
  margin: 0 auto;
}

/* RESPONSIVE */
@media only screen and (max-width: 600px) {
  .login>.box { 
    /* min-width: auto; */
    max-width: 100vw;
  }

  .login .logo>span {
    width: 8.5rem;
  }
}