.main {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

.main>.body {
  display: flex;
  flex-direction: column;
  width: calc(100% - 15rem);
  background-color: #EAEAEA;
}

.main>.body .section {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.main>.body .section .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
}

.main>.body .section .box {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 1rem;
  border-radius: 2px;
  margin: 0 1rem 1rem 0;
  min-width: 22rem;
  cursor: pointer;
  height: fit-content;
  min-height: 6.6rem;
  max-width: 22rem;
}

.main>.body .section .box>.ui.header {
  margin-bottom: .5rem;
}

.main>.body .section .box .details>.ui.header {
  margin: 0;
  font-weight: 400;
}

.header .svg-icon.close {
  height: 17px;
  width: 17px;
  background-size: 17px 17px;
  margin: auto 0;
  margin-left: 1rem;
}

/* RESPONSIVE */
@media only screen and (max-width: 600px) {
  .main {
    display: flex;
    flex-direction: column;
  }

  .main>.body {
    height: 100%;
    width: 100vw;
  }

  .main>.body>.section {
    background-color: #EAEAEA;
    width: 100%;
    height: calc(100% - 4.25rem);
  }

  .main>.body .section .grid,
  .main>.body .section .grid .box {
    width: 100%;
    margin-right: 0;
  }

  .main>.body .section .box {
    min-width: auto;
  }
}