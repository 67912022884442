.Toastify__toast {
  padding: .7rem 1.46666667rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.Toastify__toast-body>.content {
  color: #FFFFFF !important;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 0;
}

.Toastify__toast-body>.content>.ui.header {
  color: #FFFFFF !important;
  margin-top: 0;
}

.Toastify__toast-body>.content>.msg {
  color: #FFFFFF !important;
  font-weight: 400;
}

.Toastify__toast>.svg-icon.close {
  width: 14px;
  height: 14px;
  background-size: 14px;
  cursor: pointer;
}

.Toastify__toast--success {
  background-color: #8CD87C;
}

.Toastify__toast--error {
  background-color: #F95F62;
}