.svg-icon {
  display: block;
  text-indent: -9999px;
  margin: 0;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  cursor: pointer;
  background-repeat: no-repeat;
}

/********************
  SERVICES
********************/

.svg-icon.dashboard {
  background-image: url('./svg/dashboard/white.svg')
}

.svg-icon.calendar {
  background-image: url('./svg/calendar/white.svg')
}

.svg-icon.groups.white {
  background-image: url('./svg/groups/white.svg')
}

.svg-icon.connectors.white {
  background-image: url('./svg/connectors/white.svg')
}

.svg-icon.management {
  background-image: url('./svg/management/white.svg')
}

.svg-icon.profile.white {
  background-image: url('./svg/profile/white.svg')
}

.svg-icon.settings.white {
  background-image: url('./svg/settings/white.svg')
}

/********************/

.svg-icon.sub-menu {
  background-image: url('./svg/sub-menu/white.svg')
}

.svg-icon.sub-menu.gray {
  background-image: url('./svg/sub-menu/gray.svg')
}

.svg-icon.sub-menu.dark-blue {
  background-image: url('./svg/sub-menu/dark-blue.svg')
}

.svg-icon.close {
  background-image: url('./svg/close/gray.svg')
}

.svg-icon.close.black {
  background-image: url('./svg/close/black.svg')
}

.svg-icon.close.white {
  background-image: url('./svg/close/white.svg')
}

.svg-icon.close.darkblue {
  background-image: url('./svg/close/darkblue.svg')
}

.svg-icon.edit {
  background-image: url('./svg/edit/white.svg')
}

.svg-icon.alarm {
  background-image: url('./svg/alarm/white.svg')
}

.svg-icon.alarm.darkblue {
  background-image: url('./svg/alarm/darkblue.svg')
}

.svg-icon.alert {
  background-image: url('./svg/alert/red.svg')
}

.svg-icon.check {
  background-image: url('./svg/check/green.svg')
}

.svg-icon.check.white {
  background-image: url('./svg/check/white.svg')
}

.svg-icon.trigger-up {
  background-image: url('./svg/trigger/up-red.svg')
}

.svg-icon.trigger-down {
  background-image: url('./svg/trigger/down-blue.svg')
}

.svg-icon.divider {
  background-image: url('./svg/divider/white.svg')
}

.svg-icon.stop {
  background-image: url('./svg/stop/white.svg')
}

.svg-icon.add {
  background-image: url('./svg/add/white.svg')
}

.svg-icon.search.white {
  background-image: url('./svg/search/white.svg')
}

.svg-icon.search.darkblue {
  background-image: url('./svg/search/darkblue.svg')
}

.svg-icon.flame {
  background-image: url('./svg/flame/red.svg')
}

.svg-icon.snowflake {
  background-image: url('./svg/snowflake/blue.svg')
}

.svg-icon.area {
  background-image: url('./svg/area/white.svg')
}

.svg-icon.device {
  background-image: url('./svg/device/white.svg')
}

.svg-icon.scene {
  background-image: url('./svg/scene/white.svg')
}

.svg-icon.trigger {
  background-image: url('./svg/trigger/white.svg')
}

.svg-icon.boiler {
  background-image: url('./svg/coffee/white.svg')
}

.svg-icon.presence {
  background-image: url('./svg/presence/white.svg')
}

.svg-icon.tasks {
  background-image: url('./svg/task/white.svg')
}

.svg-icon.projects {
  background-image: url('./svg/diagram/white.svg')
}

.svg-icon.equipment {
  background-image: url('./svg/gears/white.svg')
}

.svg-icon.taskTypes {
  background-image: url('./svg/check-box/white.svg')
}