.app-header-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #FFFFFF;
  height: 4.25rem;
}

.app-header-bar>.left {
  padding: 1rem 2rem 1rem
}

.app-header-bar>.left>.ui.header {
  margin: auto 0;
}

.app-header-bar>.right {
  display: flex;
  flex-direction: row;
}

.app-header-bar .block {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 0 1rem;
  border-left: 1px solid #eaeaea;
}

.app-header-bar .block.add>.icon,
.app-header-bar .block.search>.ui.input,
.app-header-bar .block.alerts>.icon,
.app-header-bar .block.user-dropdown>.ui.dropdown {
  margin: auto 0;
}

.app-header-bar .block.search .ui.input>input {
  border: none;
}

.app-header-bar .block.add,
.app-header-bar .block.alerts {
  cursor: pointer;
}

.app-header-bar .block.add>.icon,
.app-header-bar .block.alerts>.icon {
  font-size: 1.2rem;
}