body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-image: -webkit-linear-gradient(bottom left, #172AAF, #03589C);
  background-image: linear-gradient(to top right, #172AAF, #03589C);
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui.button.primary {
  background-color: #172AAF;
}

.ui.button.primary:hover,
.ui.button.primary:active,
.ui.button.primary:focus {
  background-color: rgb(41, 60, 204);
}

.ui.modal,
.ui.dropdown>.menu,
.ui.input>input,
.ui.dropdown,
.ui.button,
.ui.button.primary,
.ui.table {
  border-radius: 2px !important;
}

.ui.modal .ui.form button[type="submit"] {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.row.spc-btwn {
  justify-content: space-between;
}
.Toastify__toast {
  padding: .7rem 1.46666667rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.Toastify__toast-body>.content {
  color: #FFFFFF !important;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 0;
}

.Toastify__toast-body>.content>.ui.header {
  color: #FFFFFF !important;
  margin-top: 0;
}

.Toastify__toast-body>.content>.msg {
  color: #FFFFFF !important;
  font-weight: 400;
}

.Toastify__toast>.svg-icon.close {
  width: 14px;
  height: 14px;
  background-size: 14px;
  cursor: pointer;
}

.Toastify__toast--success {
  background-color: #8CD87C;
}

.Toastify__toast--error {
  background-color: #F95F62;
}
.svg-icon {
  display: block;
  text-indent: -9999px;
  margin: 0;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  cursor: pointer;
  background-repeat: no-repeat;
}

/********************
  SERVICES
********************/

.svg-icon.dashboard {
  background-image: url(/static/media/white.81df1034.svg)
}

.svg-icon.calendar {
  background-image: url(/static/media/white.7ba808f8.svg)
}

.svg-icon.groups.white {
  background-image: url(/static/media/white.863dbdbb.svg)
}

.svg-icon.connectors.white {
  background-image: url(/static/media/white.78e60206.svg)
}

.svg-icon.management {
  background-image: url(/static/media/white.620381d3.svg)
}

.svg-icon.profile.white {
  background-image: url(/static/media/white.34531302.svg)
}

.svg-icon.settings.white {
  background-image: url(/static/media/white.2f6d226f.svg)
}

/********************/

.svg-icon.sub-menu {
  background-image: url(/static/media/white.8223d193.svg)
}

.svg-icon.sub-menu.gray {
  background-image: url(/static/media/gray.51d3162d.svg)
}

.svg-icon.sub-menu.dark-blue {
  background-image: url(/static/media/dark-blue.869df621.svg)
}

.svg-icon.close {
  background-image: url(/static/media/gray.859c6f08.svg)
}

.svg-icon.close.black {
  background-image: url(/static/media/black.51f491af.svg)
}

.svg-icon.close.white {
  background-image: url(/static/media/white.d790d009.svg)
}

.svg-icon.close.darkblue {
  background-image: url(/static/media/darkblue.2c2a438b.svg)
}

.svg-icon.edit {
  background-image: url(/static/media/white.4036d821.svg)
}

.svg-icon.alarm {
  background-image: url(/static/media/white.f521a94b.svg)
}

.svg-icon.alarm.darkblue {
  background-image: url(/static/media/darkblue.e5d75c84.svg)
}

.svg-icon.alert {
  background-image: url(/static/media/red.6aa43078.svg)
}

.svg-icon.check {
  background-image: url(/static/media/green.8a7ca56a.svg)
}

.svg-icon.check.white {
  background-image: url(/static/media/white.0651d9b5.svg)
}

.svg-icon.trigger-up {
  background-image: url(/static/media/up-red.c42199aa.svg)
}

.svg-icon.trigger-down {
  background-image: url(/static/media/down-blue.5e5d430c.svg)
}

.svg-icon.divider {
  background-image: url(/static/media/white.e19cc33e.svg)
}

.svg-icon.stop {
  background-image: url(/static/media/white.59c420fe.svg)
}

.svg-icon.add {
  background-image: url(/static/media/white.1631a8c5.svg)
}

.svg-icon.search.white {
  background-image: url(/static/media/white.9dd64d8f.svg)
}

.svg-icon.search.darkblue {
  background-image: url(/static/media/darkblue.03ce5625.svg)
}

.svg-icon.flame {
  background-image: url(/static/media/red.871f1880.svg)
}

.svg-icon.snowflake {
  background-image: url(/static/media/blue.be9a333b.svg)
}

.svg-icon.area {
  background-image: url(/static/media/white.d9ba4053.svg)
}

.svg-icon.device {
  background-image: url(/static/media/white.ef535e51.svg)
}

.svg-icon.scene {
  background-image: url(/static/media/white.eed7ac61.svg)
}

.svg-icon.trigger {
  background-image: url(/static/media/white.0843b06f.svg)
}

.svg-icon.boiler {
  background-image: url(/static/media/white.7068ecab.svg)
}

.svg-icon.presence {
  background-image: url(/static/media/white.49ea079c.svg)
}

.svg-icon.tasks {
  background-image: url(/static/media/white.ee50c34e.svg)
}

.svg-icon.projects {
  background-image: url(/static/media/white.4a075f81.svg)
}

.svg-icon.equipment {
  background-image: url(/static/media/white.b092fcb1.svg)
}

.svg-icon.taskTypes {
  background-image: url(/static/media/white.d249c679.svg)
}
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
}

.login .ui.header {
  text-align: center;
  color: #FFF;
}

.login>.box {
  background-color: #FFF;
  border-radius: 2px;
  padding: 2rem;
  min-width: 30rem;
  min-height: 15rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login>.box .ui.header {
  color: #000;
}

.login>.box .ui.button {
  margin: 1rem auto 0;
}

.login .logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 5rem;
  width: 100%;
  text-align: center;
}

.login .logo>span {
  background: url(/static/media/fldrilling_logo.bc2ab5bb.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 9rem;
  display: inline-block;
  margin: 0 auto;
}

/* RESPONSIVE */
@media only screen and (max-width: 600px) {
  .login>.box { 
    /* min-width: auto; */
    max-width: 100vw;
  }

  .login .logo>span {
    width: 8.5rem;
  }
}
.main {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

.main>.body {
  display: flex;
  flex-direction: column;
  width: calc(100% - 15rem);
  background-color: #EAEAEA;
}

.main>.body .section {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.main>.body .section .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
}

.main>.body .section .box {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 1rem;
  border-radius: 2px;
  margin: 0 1rem 1rem 0;
  min-width: 22rem;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 6.6rem;
  max-width: 22rem;
}

.main>.body .section .box>.ui.header {
  margin-bottom: .5rem;
}

.main>.body .section .box .details>.ui.header {
  margin: 0;
  font-weight: 400;
}

.header .svg-icon.close {
  height: 17px;
  width: 17px;
  background-size: 17px 17px;
  margin: auto 0;
  margin-left: 1rem;
}

/* RESPONSIVE */
@media only screen and (max-width: 600px) {
  .main {
    display: flex;
    flex-direction: column;
  }

  .main>.body {
    height: 100%;
    width: 100vw;
  }

  .main>.body>.section {
    background-color: #EAEAEA;
    width: 100%;
    height: calc(100% - 4.25rem);
  }

  .main>.body .section .grid,
  .main>.body .section .grid .box {
    width: 100%;
    margin-right: 0;
  }

  .main>.body .section .box {
    min-width: auto;
  }
}
.sidebar {
  background-image: -webkit-linear-gradient(bottom left, #172AAF, #03589C);
  background-image: linear-gradient(to top right, #172AAF, #03589C);
  height: 100%;
  width: 15rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
}

.sidebar .logo {
  padding: 0 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: 5rem;
  width: 100%;
  text-align: center;
}

.sidebar .logo>span {
  background: url(/static/media/fldrilling_logo_white.9b702796.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: inline-block;
}
/* 
.sidebar .logo>.icon {
  margin: 0;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  margin-bottom: .5rem;
  font-size: 2rem;
}

.sidebar .logo>.ui.header {
  color: #FFFFFF;
  margin: 0;
  text-align: center;
} */

.sidebar .services>.service {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.sidebar .services>.service .svg-icon {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  margin-right: .5rem;
}

.sidebar .services>.service .svg-icon.taskTypes {
  width: 26px;
  height: 26px;
  background-size: 20px 26px;
  margin-left: 3px;
  margin-right: calc(.5rem - 3px);
}

.sidebar .services>.service .link {
  color: #FFFFFF;
}

.sidebar>.copyright {
  text-align: center;
  width: 100%;
  padding-bottom: 1rem;
  color: #FFFFFF;
  font-size: .8rem;
  font-weight: 400;
  margin-top: auto;
}

/* RESPONSIVE */
@media only screen and (max-width: 600px) {
  .sidebar {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    position: relative;
    display: block;
  }

  .sidebar .services,
  .sidebar>.copyright {
    display: none;
  }

  .sidebar .logo {
    margin: 0 auto;
    height: 3rem;
    width: 100%;
  }
  
  .sidebar .logo>span {
    height: 100%;
    width: 5rem;
  }
}
.app-header-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #FFFFFF;
  height: 4.25rem;
}

.app-header-bar>.left {
  padding: 1rem 2rem 1rem
}

.app-header-bar>.left>.ui.header {
  margin: auto 0;
}

.app-header-bar>.right {
  display: flex;
  flex-direction: row;
}

.app-header-bar .block {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 0 1rem;
  border-left: 1px solid #eaeaea;
}

.app-header-bar .block.add>.icon,
.app-header-bar .block.search>.ui.input,
.app-header-bar .block.alerts>.icon,
.app-header-bar .block.user-dropdown>.ui.dropdown {
  margin: auto 0;
}

.app-header-bar .block.search .ui.input>input {
  border: none;
}

.app-header-bar .block.add,
.app-header-bar .block.alerts {
  cursor: pointer;
}

.app-header-bar .block.add>.icon,
.app-header-bar .block.alerts>.icon {
  font-size: 1.2rem;
}
.no-data.box {
  justify-content: center !important;
  cursor: default !important;
}

.no-data.box .ui.header {
  margin: 0 !important;
  text-align: center;
}
.data-panel {
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 100%;
  background-color: white;
  padding: 1rem 2rem;
  border-top: 1px solid #eaeaea;
  min-width: 29rem;
  max-width: 29rem;
  max-height: 100vh;
  overflow-y: auto;
}

.data-panel .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  min-height: 1.5rem;
}

.data-panel .ui.header,
.data-panel .assignment-table>.header {
  margin-bottom: .5rem;
}

.data-panel .header>.ui.header {
  margin: 0 !important;
}

.data-panel .assignment-table {
  margin-top: 1rem;
}

.data-panel .assignment-table .remove-cell {
  text-align: right;
  color: #f95f62;
  font-weight: 700;
  cursor: pointer;
}

.data-panel .ui.button.negative.delete-btn {
  margin: 0;
  margin-top: 1rem;
  background-color: #f95f62;
  color: white;
  min-height: 2.5rem;
}

.data-panel .ui.form .row>.field {
  width: 50%;
  margin: 0;
  margin-right: .5rem;
}

.data-panel .ui.form .row>.field:last-child {
  margin: 0;
  margin-left: .5rem;
}

.data-panel .ui.form .row>.field>.ui.button {
  width: 100%;
  margin: 0;
}

.data-panel .task-details {
  margin-bottom: 1rem;
}

.data-panel .ui.table tr>td:first-child {
  font-weight: 700;
  background-color: #f9fafb;
  border-right: 1px solid rgba(34,36,38,.15)
}

/* RESPONSIVE */
@media only screen and (max-width: 950px) {
  .data-panel {
    position: fixed;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  
  .data-panel .ui.table tr>td:first-child {
    background-color: transparent;
  }
}
.assignment-table {
  margin: 0;
}

.ui.modal.new-data-modal {
  display: flex;
  flex-direction: column;
}

.ui.modal.new-data-modal>.header {
  display: flex;
  justify-content: space-between;
  background-color: #172AAF;
  border-radius: 2px 2px 0 0;
}

.ui.modal.new-data-modal>.header>.ui.header {
  color: white;
  font-weight: 400;
  margin: 0;
}

.ui.modal.new-data-modal .time-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.ui.modal.new-data-modal .time-section .ui.button {
  margin-top: 1rem;
}

.ui.modal.new-data-modal .time-section .time {
  min-width: 95px;
  margin-right: 2rem;
}

.ui.modal.new-data-modal .time-section .time>.ui.header {
  margin: 0;
}

.ui.modal.new-data-modal .time-section .time.elapsed>h2 {
  color: red;
}
