.data-panel {
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 100%;
  background-color: white;
  padding: 1rem 2rem;
  border-top: 1px solid #eaeaea;
  min-width: 29rem;
  max-width: 29rem;
  max-height: 100vh;
  overflow-y: auto;
}

.data-panel .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  min-height: 1.5rem;
}

.data-panel .ui.header,
.data-panel .assignment-table>.header {
  margin-bottom: .5rem;
}

.data-panel .header>.ui.header {
  margin: 0 !important;
}

.data-panel .assignment-table {
  margin-top: 1rem;
}

.data-panel .assignment-table .remove-cell {
  text-align: right;
  color: #f95f62;
  font-weight: 700;
  cursor: pointer;
}

.data-panel .ui.button.negative.delete-btn {
  margin: 0;
  margin-top: 1rem;
  background-color: #f95f62;
  color: white;
  min-height: 2.5rem;
}

.data-panel .ui.form .row>.field {
  width: 50%;
  margin: 0;
  margin-right: .5rem;
}

.data-panel .ui.form .row>.field:last-child {
  margin: 0;
  margin-left: .5rem;
}

.data-panel .ui.form .row>.field>.ui.button {
  width: 100%;
  margin: 0;
}

.data-panel .task-details {
  margin-bottom: 1rem;
}

.data-panel .ui.table tr>td:first-child {
  font-weight: 700;
  background-color: #f9fafb;
  border-right: 1px solid rgba(34,36,38,.15)
}

/* RESPONSIVE */
@media only screen and (max-width: 950px) {
  .data-panel {
    position: fixed;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  
  .data-panel .ui.table tr>td:first-child {
    background-color: transparent;
  }
}