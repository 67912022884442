.ui.modal.new-data-modal {
  display: flex;
  flex-direction: column;
}

.ui.modal.new-data-modal>.header {
  display: flex;
  justify-content: space-between;
  background-color: #172AAF;
  border-radius: 2px 2px 0 0;
}

.ui.modal.new-data-modal>.header>.ui.header {
  color: white;
  font-weight: 400;
  margin: 0;
}

.ui.modal.new-data-modal .time-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.ui.modal.new-data-modal .time-section .ui.button {
  margin-top: 1rem;
}

.ui.modal.new-data-modal .time-section .time {
  min-width: 95px;
  margin-right: 2rem;
}

.ui.modal.new-data-modal .time-section .time>.ui.header {
  margin: 0;
}

.ui.modal.new-data-modal .time-section .time.elapsed>h2 {
  color: red;
}